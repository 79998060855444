import React from 'react';
import { t } from 'i18next';
import { Input, Table } from 'reactstrap';
import { TableBody, TableHead } from '../../table';
import { ButtonActionCustom } from '../../buttons';
import ChoiceField from './ChoiceField';
import TextField from './TextField';
import TelField from './TelField';

class CollectionField extends React.Component {
    state = {
        data: this.props.field.data ? this.props.field.data : []
    };

    render() {
        return (
            <Table size="sm">
                <TableHead>
                    <tr>
                        {this.fields().map(field => (
                            <th key={'head_' + field.name} className={field.class}>
                                {field.label} {field.required ? '*' : ''}
                            </th>
                        ))}
                        {this.props.field.entry_form.allow_add && (
                            <th className='text-right'>
                                <ButtonActionCustom id='extrafieldsadd' size="xs" color="success" icon="plus" tooltip={t('common:Add')} onClick={this.handOptionAddClick} />
                            </th>
                        )}
                    </tr>
                </TableHead>
                <TableBody cols={2}>
                    {this.state.data.map((record, index) => (
                        <tr key={index}>
                            {this.fields().map(field => (
                                <td key={record.id + '_' + field.name}>
                                    {this.renderField(index, field, record)}
                                </td>
                            ))}
                            {this.props.field.entry_form.allow_delete && (
                                <td className='text-right'>
                                    <ButtonActionCustom id={'extrafieldsremove' + index} size="xs" color="danger" icon="trash" tooltip={t('common:Remove')} onClick={() => this.handOptionRemoveClick(index)} />
                                </td>
                            )}
                        </tr>
                    ))}
                </TableBody>
            </Table>
        );
    }

    renderField = (index, field, record) => {
        let newField = {
            ...field, ...{
                name: field.name,
                data: record[field.name] || ''
            }
        };

        switch (field.type) {
            case 'choice':
                return <ChoiceField key={newField.name} onChangeValue={(name, value) => this.handleOptionChange(index, name, value)} field={newField} data={record} />
            case 'tel':
                return <TelField key={newField.name} onChangeValue={(e) => this.handleOptionChange(index, newField.name, e.target.value)} field={newField} data={record} />
            default:
                //return <TextField key={newField.name} onChangeValue={(e) => this.handleOptionChange(index, newField.name, e.target.value)} field={newField} data={record} />

                return (
                    <Input type={newField.type} name={newField.name} id={newField.name}
                        onChange={(e) => this.handleOptionChange(index, newField.name, e.target.value)}
                        value={newField.data || ''}
                        autoComplete="off"
                    />
                );
        }
    }

    options = () => Object.values(this.props.field.options.choices).map(choice => {
        return { value: choice, label: choice }
    });
    selectedOption = (value) => {
        let options = this.options();

        let selected = null;
        if (value) selected = options.filter(option => option.value === value)[0];

        return selected ? selected : options[0];
    }

    handOptionAddClick = () => {
        let data = this.state.data;

        let object = {};
        this.fields().forEach(field => object[field.name] = null);

        data.push(object);

        this.setState({ data: data });
    }

    handOptionRemoveClick = (index) => {
        let data = this.state.data;
        data.splice(index, 1);
        this.setState({ data: data }, () => this.props.onChangeValue(this.props.field.name, data));
    }

    handleOptionChange = (index, key, value) => {
        let data = this.state.data;
        data[index][key] = value;
        this.setState({ data: data }, () => this.props.onChangeValue(this.props.field.name, data));
    }

    fields = () => this.props.field.entry_form.fields.filter(field => field.type !== 'hidden');
    fieldCount = () => this.fields().length;
}

export default CollectionField;