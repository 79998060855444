import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { t } from 'i18next';
import swal from 'sweetalert';
import { UtilNotification } from './../utils';
import { LocalStorageService } from './../services/local-storage.service.js';

class Notification extends React.Component {

    componentDidMount() {
        this.showFlashMessages();
        this.showPopupMessages();
    }

    render() {
        return null;
    }

    showFlashMessages = () => {
        UtilNotification.toastDismiss();

        let flashMsg = LocalStorageService.peek('FlashMessage');
        if (!flashMsg) return;

        let message = '';
        if (typeof flashMsg.message === 'string') {
            message = flashMsg.message;
        } else {
            for (let key in flashMsg.message) {
                message += flashMsg.message[key] + '\n';
            }
        }

        switch (flashMsg.status) {
            case toast.TYPE.ERROR:
                UtilNotification.toastError(message);
                break;
            case toast.TYPE.SUCCESS:
                UtilNotification.toastSuccess(message);
                break;
            case toast.TYPE.WARNING:
                UtilNotification.toastWarning(message);
                break;
            default:
                UtilNotification.toastInfo(message);
                break;
        }
    }

    showPopupMessages = () => {
        let popupMessage = LocalStorageService.peek('PopupMessage');
        if (!popupMessage) return;

        swal({
            title: popupMessage.title ? popupMessage.title : '',
            text: popupMessage.message,
            icon: popupMessage.status ? popupMessage.status : 'info',
            buttons: {
                confirm: {
                    text: t('common:Confirm'),
                    value: true,
                    visible: true,
                    className: 'bg-primary btn-info',
                    closeModal: true
                }
            }
        });
    }
}

export default Notification;