import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react'; // Tiny MCE account --> sven@sanmax.be
import { UtilForm } from '../../../utils/form.util';
import { ApiLinkService } from '../../../services/api/index';

class TranslatablEditorField extends React.Component {
    constructor(props) {
        super(props);

        let isFullyFeatured = props.fullyFeatured || ['bodyHtml'].indexOf(props.field.name) > -1 || props.field.options.fullyFeatured;

        this.state = {
            layout: props.layout ? props.layout : {},
            isFullyFeatured: isFullyFeatured,
            loading: isFullyFeatured ? true : false,
            activeTab: this.props.client ? this.props.client.nativeLanguage : 'nl',
            value: this.props.field.data ? this.props.field.data : '',
            tags: props.field.options.tags ? props.field.options.tags : {},
            links: {}
        }
    }

    componentDidMount = () => {
        this.props.group.languages.forEach(lng => {
            let textareaEl = document.getElementById(this.props.field.name + '_' + lng);
            if (textareaEl) textareaEl.addEventListener('input', (e) => this.onChangeValue(lng, e.target.value));
        });

        if (this.state.isFullyFeatured) {
            this.loadLinks();
        }
    }

    renderLoader = () => (
        <div className='loader'>
            <span className='fa fa-spinner fa-spin fa-lg mr-2'></span>
            Loading
        </div>
    );

    render() {
        if (this.state.loading) return this.renderLoader();

        return (
            <>
                <Nav tabs>
                    {this.props.group.languages.map(lng => (
                        <NavItem key={lng}>
                            <NavLink id={'tab-' + lng} className={this.state.activeTab === lng ? 'active' : ''}
                                onClick={() => this.toggleTab(lng)}
                                data-tab={lng}
                            >
                                {t('language:' + lng)}
                                <span id={'tab-error-indicator-' + lng} className="indicator text-danger float-right hidden">1</span>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="bg-white">
                    {this.props.group.languages.map(lng => (
                        <TabPane tabId={lng} key={lng} data-tab={lng}>
                            <Editor id={'editor-' + this.props.field.name + '_' + lng} name={this.props.field.name}
                                // apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                value={this.state.value[lng]}
                                init={UtilForm.loadEditorConfig(this.state, this.props.field.name + '_' + lng)}
                                onEditorChange={this.onEditorChange}
                            />
                        </TabPane>
                    ))}
                </TabContent>
            </>
        );
    }

    toggleTab = tab => this.setState({ activeTab: tab });
    loadLinks = () => ApiLinkService.getLinks(this.props.group.id).then(result => this.setState({ loading: false, links: result }));
    onEditorChange = (content, _) => this.onChangeValue(this.state.activeTab, content);

    onChangeValue = (lng, newValue) => {
        let value = {
            ...this.state.value,
            [lng]: newValue
        }
        this.setState({ value: value }, () => this.props.onManualChangeValue(this.props.field.name, value));
    }
}


const mapStateToProps = state => ({ client: state.client.logged_in, group: state.group });

export default connect(mapStateToProps)(TranslatablEditorField);